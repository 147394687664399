<script>
</script>

<section id="about">
  <h5>Chi sono</h5>
  <h1>Scopri chi sono</h1>
  <p>
    Laureato in scienze motorie e trainer certificato ISSA, con alle spalle un
    passato professionale da personal trainer, direttore tecnico e fitness
    manager. Specializzato nella programmazione di allenamenti finalizzati ad
    ipertrofia e ricomposizione corporea. L'empatia, la passione per il mio
    lavoro e l'esperienza più che decennale sono le componenti che metto al
    servizio del cliente con l'unico obiettivo di migliorare il suo stile di
    vita.
  </p>
  <h2 class="description">Perchè scegliere me?</h2>
  <ul>
    <li>Per essere maggiormente motivato durante gli allenamenti</li>
    <li>
      Per raggiungere i propri obiettivi di forma fisica e benessere con
      allenamenti mirati
    </li>
    <li>Per ridurre al minimo le perdite di tempo massimizzando i risultati</li>
    <li>Per garantirsi una programmazione funzionale alle proprie esigenze</li>
    <li>
      Per garantirsi la possibilità di eseguire ogni singolo esercizio al meglio
      delle proprie possibilità
    </li>
    <li>
      Per controllare periodicamente i risultati ottenuti e verificare il
      raggiungimento degli obiettivi, con la finalità di programmarne di nuovi
    </li>
  </ul>
</section>

<style>
  #about {
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 1rem;
    max-width: 100%;
  }
  h1,
  h5 {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  img {
    max-width: 100%;
  }
</style>
